/*App.jsx*/

import React from 'react';
import './App.css';
import IconRow from './IconRow'; // Importe o componente IconRow
import CookieNotification from './CookieNotification'; // Importe o componente CookieNotification

function App() {

  // Bloqueia o botão direito do mouse na tela
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  // Bloqueia função F12
  document.addEventListener('keydown', function (event) {
    if (event.key === 'F12') {
      event.preventDefault();
      //alert('O uso da tecla F12 está bloqueado neste site.');
    }
  });

  return (
    <div className="App" onContextMenu={handleContextMenu}>
      <CookieNotification />
      <div className="logoSabe">
        <img src="/imagens/logoSabe.png" alt="Logo Sabe" />
      </div>
      <IconRow /> {/* Adicione o componente IconRow aqui */}
      <div className="footer">
        Todos os direitos reservados à <a href="https://www.editoralt.com.br/">Editora LT</a>.
        Proibida a reprodução total ou parcial, sejam quais forem os meios empregados,
        nos termos da Lei Federal 9610/1998
      </div>
    </div>
  );
}

export default App;
