/* CookieNotification.jsx */

import React, { useState } from 'react';
import Cookies from 'js-cookie';

const CookieNotification = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(Cookies.get('acceptedCookies'));

  const handleAcceptCookies = () => {
    Cookies.set('acceptedCookies', 'true', { expires: 365 }); // Cookies válidos por um ano
    setAcceptedCookies(true);
  };

  const handleDenyCookies = () => {
    // Adicione aqui a lógica para redirecionar para a página anterior
    window.history.back();
  };

  if (acceptedCookies) {
    return null; // Não mostra a notificação se os cookies já foram aceitos
  }

  return (
    <div className="cookie-notification-container">
      <p>
        Este site utiliza cookies para melhorar a experiência do usuário. Ao continuar, você concorda com o uso de cookies.
        <button onClick={handleAcceptCookies}>Aceitar</button>
        <button onClick={handleDenyCookies}>Negar</button>
      </p>
    </div>
  );
};

export default CookieNotification;